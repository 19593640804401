import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, throwError, forkJoin } from "rxjs";
import { filter, map, catchError, first, tap } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";

export enum OpencvStatus {
  Waiting, // for orders
  Loading,
  Initialising,
  Ready
}

declare var cv: any;

@Injectable({
  providedIn: "root"
})
export class OpencvService {
  private OPENCV_URL = "opencv.js";

  constructor(private http: HttpClient) { }

  private _status = new BehaviorSubject<OpencvStatus>(OpencvStatus.Waiting);
  public get status(): Observable<OpencvStatus> {
    return this._status.asObservable();
  }

  public get cv(): Observable<any> {
    this.loadOpenCV();
    return this._status.pipe(
      filter((val: OpencvStatus) => val === OpencvStatus.Ready),
      // Do not use mapTo, strange bug; runtime errors with "cv not defined"
      // Maybe because compiler takes mapTo's input argument as a constant
      map(_ => cv),
      first()
    );
  }

  private loadOpenCV() {
    if (this._status.value === OpencvStatus.Waiting) {
      console.log('Loading OpenCV')
      this._status.next(OpencvStatus.Loading);
      const script = document.createElement("script");
      script.src = this.OPENCV_URL;
      script.type = "text/javascript";
      script.async = true;
      script.onload = () => {
        this._status.next(OpencvStatus.Initialising);
        console.log('Initialising OpenCV')
        cv.onRuntimeInitialized = () => this._status.next(OpencvStatus.Ready);
        console.log('OpenCV Ready')
      };
      script.onerror = (err: any) => {
        console.log(err);
        this._status.error(
          `Unable to load OpenCV library from ${this.OPENCV_URL}`
        );
      };
      document.body.appendChild(script);
    }
  }

  createFileFromUrl(path: string, url: string): Observable<boolean> {
    return forkJoin({
      http: this.http.get(url, { responseType: "arraybuffer" }),
      cv: this.cv
    }).pipe(
      map(val => {
        const data = new Uint8Array(val.http);
        val.cv.FS_createDataFile("/", path, data, true, false, false);
        return true;
      }),
      catchError(err => {
        console.error(err);
        return throwError(`Unable to load resource from ${url} into ${path}`);
      })
    );
  }



  loadURLToHTMLCanvas(
    url: string,
    canvas?: HTMLCanvasElement
  ): Observable<HTMLCanvasElement> {
    const c = (canvas) ? canvas : document.createElement("canvas")
    return new Observable(observer => {
      const image = new Image();
      image.crossOrigin = "anonymous";
      image.onload = () => {
        c.width = image.width;
        c.height = image.height;
        c
          .getContext("2d", { alpha: false })
          ?.drawImage(image, 0, 0, image.width, image.height);
        observer.next(c);
        observer.complete();
      };
      image.onerror = err => {
        console.error(err);
        observer.error(`Unable to load ${url}`);
      };
      image.src = url;
    });
  }

  loadFileToHTMLCanvas(
    file: File,
    canvas?: HTMLCanvasElement
  ): Observable<HTMLCanvasElement> {
    const url = URL.createObjectURL(file);
    return this.loadURLToHTMLCanvas(url, canvas).pipe(
      tap(_ => URL.revokeObjectURL(url)),
      catchError(_ => throwError(`Unable to load ${file.name}`))
    );
  }

  createImageDatafromURL(url: string): Observable<ImageData> {
    return this.loadURLToHTMLCanvas(url).pipe(
      map(canvas =>
        (canvas.getContext("2d", {
          alpha: false
        }) as CanvasRenderingContext2D).getImageData(
          0,
          0,
          canvas.width,
          canvas.height
        )
      )
    );
  }

  // createImageBitmap and OffscreenCanvas are useful, but not supported
  // Edge, Firefox and (probably) Safari
  createImageDatafromFile(file: File): Observable<ImageData> {
    const url = URL.createObjectURL(file);
    return this.createImageDatafromURL(url).pipe(
      tap(_ => URL.revokeObjectURL(url)),
      catchError(_ => throwError(`Unable to load ${file.name}`))
    );
  }
}
