import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  AfterViewInit
} from "@angular/core";

import { forkJoin } from "rxjs";

import { OpencvService } from "../opencv.service";

@Component({
  selector: 'app-face',
  templateUrl: './face.component.html',
  styleUrls: ['./face.component.css']
})
export class FaceComponent implements OnInit, AfterViewInit {
  imageUrl = "assets/face.jpg";

  private classifiersLoaded = false;

  private imageData: ImageData;

  // HTML Element references
  @ViewChild("canvasInput")
  canvasInput: ElementRef;
  @ViewChild("canvasOutput")
  canvasOutput: ElementRef;

  private cv: any;

  // Inject the NgOpenCVService
  constructor(private opencvService: OpencvService) {
    this.opencvService.cv.subscribe((cv: any) => (this.cv = cv));
  }

  public ngOnInit() {
    forkJoin(
      this.opencvService.createFileFromUrl(
        "haarcascade_frontalface_default.xml",
        "assets/opencv/data/haarcascades/haarcascade_frontalface_default.xml"
      ),
      this.opencvService.createFileFromUrl(
        "haarcascade_eye.xml",
        "assets/opencv/data/haarcascades/haarcascade_eye.xml"
      )
    ).subscribe(_ => this.classifiersLoaded = true);
  }

  public ngAfterViewInit(): void {
    this.opencvService
      .loadURLToHTMLCanvas(this.imageUrl, this.canvasInput.nativeElement)
      .subscribe();

    this.opencvService.createImageDatafromURL(this.imageUrl).subscribe(
      (imageData: ImageData) => this.imageData = imageData
    )
  }

  public detectFace() {
    if (this.cv && this.classifiersLoaded && (this.imageData)) {
      this.clearOutputCanvas();
      this.findFaceAndEyes(this.imageData);
      console.log("Face detected");
    } else {
      console.log('Not ready')
    }
  }

  public readDataUrl(event: any) {
    if (event.target.files.length) {
      const file: File = event.target.files[0];
      this.opencvService
        .loadFileToHTMLCanvas(file, this.canvasInput.nativeElement)
        .subscribe();

      this.opencvService.createImageDatafromFile(file).subscribe(
        (imageData: ImageData) => this.imageData = imageData
      )
    }
  }

  private clearOutputCanvas() {
    const canvas = this.canvasOutput.nativeElement;
    const context = canvas.getContext("2d");
    context.clearRect(0, 0, canvas.width, canvas.height);
  }

  private findFaceAndEyes(imageData: ImageData) {
    const src = this.cv.matFromImageData(imageData);
    const gray = new this.cv.Mat();
    this.cv.cvtColor(src, gray, this.cv.COLOR_RGBA2GRAY, 0);
    const faces = new this.cv.RectVector();
    const eyes = new this.cv.RectVector();
    const faceCascade = new this.cv.CascadeClassifier();
    faceCascade.load("haarcascade_frontalface_default.xml");
    const eyeCascade = new this.cv.CascadeClassifier();
    eyeCascade.load("haarcascade_eye.xml");
    // detect faces
    const msize = new this.cv.Size(0, 0);
    faceCascade.detectMultiScale(gray, faces, 1.1, 3, 0, msize, msize);
    for (let i = 0; i < faces.size(); ++i) {
      const roiGray = gray.roi(faces.get(i));
      const roiSrc = src.roi(faces.get(i));
      const point1 = new this.cv.Point(faces.get(i).x, faces.get(i).y);
      const point2 = new this.cv.Point(
        faces.get(i).x + faces.get(i).width,
        faces.get(i).y + faces.get(i).height
      );
      this.cv.rectangle(src, point1, point2, [255, 0, 0, 255]);
      // detect eyes in face ROI
      eyeCascade.detectMultiScale(roiGray, eyes);
      for (let j = 0; j < eyes.size(); ++j) {
        const point3 = new this.cv.Point(eyes.get(j).x, eyes.get(j).y);
        const point4 = new this.cv.Point(
          eyes.get(j).x + eyes.get(j).width,
          eyes.get(j).y + eyes.get(j).height
        );
        this.cv.rectangle(roiSrc, point3, point4, [0, 0, 255, 255]);
      }
      roiGray.delete();
      roiSrc.delete();
    }
    this.cv.imshow(this.canvasOutput.nativeElement.id, src);
    src.delete();
    gray.delete();
    faceCascade.delete();
    eyeCascade.delete();
    faces.delete();
    eyes.delete();
  }
}
