import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  AfterViewInit
} from "@angular/core";

import { OpencvService } from "../opencv.service";
import { process } from "./forms";

@Component({
  selector: 'app-forms',
  templateUrl: './forms.component.html',
  styleUrls: ['./forms.component.css']
})
export class FormsComponent implements OnInit, AfterViewInit {
  @ViewChild("canvas") canvas: ElementRef;

  private imageUrl = "assets/forms/test_001.png";
  private imageData: ImageData;
  private cv: any;

  constructor(private opencvService: OpencvService) {
    this.opencvService.cv.subscribe((cv: any) => {this.cv = cv; this.onClick()});
  }

  ngOnInit(): void {
  }

  public ngAfterViewInit(): void {
    this.opencvService
      .loadURLToHTMLCanvas(this.imageUrl, this.canvas.nativeElement)
      .subscribe();

    this.opencvService.createImageDatafromURL(this.imageUrl).subscribe(
      (imageData: ImageData) => this.imageData = imageData
    )
  }

  public readFile(event: any) {
    if (event.target.files.length) {
      const file: File = event.target.files[0];
      this.opencvService
        .loadFileToHTMLCanvas(file, this.canvas.nativeElement)
        .subscribe();

      this.opencvService.createImageDatafromFile(file).subscribe(
        (imageData: ImageData) => this.imageData = imageData
      )
    }
  }

  public onClick() {
    process(this.imageData, this.cv, this.canvas.nativeElement)
  }
}
