declare var cv: any

interface rect {
  x: number,
  y: number,
  width: number,
  height: number
}

function types() {
  console.log(`CV_8U ${cv.CV_8U}`)
  console.log(`CV_8S ${cv.CV_8S}`)
  console.log(`CV_16U ${cv.CV_16U}`)
  console.log(`CV_16S ${cv.CV_16S}`)
  console.log(`CV_32S ${cv.CV_32S}`)
  console.log(`CV_32F ${cv.CV_32F}`)
  console.log(`CV_64F ${cv.CV_64F}`)
}

export function process(imageData: ImageData, cv: any, canvas: HTMLCanvasElement) {

  function score(image: any, rect: rect): number {
    const roi = image.roi(rect);
    const result = cv.mean(roi);
    roi.delete();
    return result[0];
  }

  function getXshift(image: any, width: number) {

    // const rows = 10;
    // const cols = 5;
    // const type = 0;
    // let mat = cv.Mat.ones(rows, cols, type);
    let scores = new cv.Mat();
    console.log(image.type())
    const roi = image.roi({x: 0, y: 0, width: 2, height: 100})
    cv.reduce(roi, scores, 1, cv.REDUCE_SUM, cv.CV_32S);
    console.log(scores)


    // const left = Math.floor(0.92*width)
    // const right = width
    // const minlength = Math.floor(0.01*width)

    // const roi = image.roi({x: left, y: 0, width : right - left, height: image.rows});
    // console.log(cv.REDUCE_SUM);
    // const result = cv.mean(roi);
    // console.log(array)
    // console.log(result[0]);
    // let scores = new cv.Mat();
    // // let scores = new cv.MatVector();
    // console.log('Starting reduce')
    // cv.reduce(image, scores, 1, cv.REDUCE_SUM, -1);
    // console.log('Finished reduce')
    // console.log(scores)
    // roi.delete();

    // black = min(scores)
    // white = max(scores)
    // Threshold = 0.3*black + 0.7*white  # Close to white. The line dominate black.
    // isblack = (scores < Threshold)
    // idx = list(range(len(isblack)))
    // leftanchor = floor(width * LEFTANCHOR)
    // for ii, l, r in zip(idx, isblack, isblack[minlength:]):
    //     if l and r:
    //         leftanchor = ii + left
    //         break
    // isblack = np.flip(isblack)
    // idx.reverse()
    // rightanchor = width
    // for ii, l, r in zip(idx, isblack, isblack[minlength:]):
    //     if l and r:
    //         rightanchor = ii + left
    //         break

    // shift = leftanchor - floor(width * LEFTANCHOR)
    // console.log(shift, width, leftanchor, rightanchor)

    // return shift, leftanchor, rightanchor
  }

  let width: number;
  let height: number;

  const src = cv.matFromImageData(imageData);
  console.log(
    'image width: ' + src.cols + '\n' +
    'image height: ' + src.rows + '\n' +
    'image size: ' + src.size().width + '*' + src.size().height + '\n' +
    'image depth: ' + src.depth() + '\n' +
    'image channels ' + src.channels() + '\n' +
    'image type: ' + src.type() + '\n');


  cv.cvtColor(src, src, cv.COLOR_BGR2GRAY);
  width = src.cols;
  height = src.rows;

  const ratio = Math.min(1.0, 500 / Math.max(width, height));
  let dsize = new cv.Size(ratio*width, ratio*height);
  cv.resize(src, src, dsize, 0, 0, cv.INTER_AREA);
  width = src.cols;
  height = src.rows;

  // AlignImage
  if (height < width) {
    console.log('Rotating 90 counter clockwise')
    cv.rotate(src, src, cv.ROTATE_90_COUNTERCLOCKWISE)
    width = src.cols;
    height = src.rows;
  }

  const leftscore = score(src, { x: Math.floor(0.01*width), y: 0, width: Math.floor(0.03*width), height: height});
  const rightscore = score(src, { x: Math.floor(0.096*width), y: 0, width: Math.floor(0.03*width), height: height});
  if (leftscore < rightscore) {
    console.log('Rotating 180 degrees')
    cv.rotate(src, src, cv.ROTATE_180)
  }

  console.log(leftscore)
  console.log(rightscore)

  // Cut bottom from image
  const image = src.roi({ x: 0, y: 0, width: width, height: Math.floor(0.82*height)});
  src.delete();

  getXshift(image, width);

  // You can try more different parameters
  cv.imshow(canvas, image);
  image.delete();
}
