<div>
  <table cellpadding="0" cellspacing="0" width="0">
      <tr>
          <td>
              <canvas #canvasInput id="canvasInput"></canvas>
          </td>
          <td>
              <canvas #canvasOutput id="canvasOutput"></canvas>
          </td>
      </tr>
      <tr>
          <td>
              <div class="caption">Input</div>
          </td>
          <td>
              <div class="caption">Output</div>
          </td>
      </tr>
  </table>
</div>
<div>
  <input type="file" (change)="readDataUrl($event)" accept="image/*"/>
  <button (click)="detectFace()">DETECT FACE</button>
</div>
